import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const LocalAuthorities = () => {
    return (
        <Layout>
            <Seo
      title="Local Authorities- BIKESAFE"
      description="Bike Safe has worked with many local authorities to provide bike parking and storage in town centres, shopping centres, government offices, in car parks, at train and bus stations, and at education establishments."   />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Local Authorities
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Bike Safe has worked with many local authorities to provide bike parking and storage in town centres, shopping centres, government offices, in car parks, at train and bus stations, and at education establishments.
                        </p>
                        <p className="flow-text">
                        Accessible bike parking encourages cycling, which reduces traffic and contributes to a greener environment. Cycling also contributes to healthy people.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/localauthoritypic.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Your service was sublime, I would definitely be recommending your company"
                                <strong> Gina Harkell, Cycling Officer, London Borough of Waltham Forrest</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default LocalAuthorities;